@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 14px;
  font-family: "Roboto";
}
.rec-slider-container {
  margin: 0px !important;
}
.text-gray-500 {text-align: justify;
}

/* .react-pdf__Document {
  display: inline-block;
} */
